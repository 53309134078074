<template>
  <div class="success">
    <!-----------------------------------
      Start Header Component
      ------------------------------------>
    <Header @openFormHeader="openFormHeader"></Header>
    <!-----------------------------------
      End Header Component
      ------------------------------------>
    <div class="flex justify-center mb-44 pt-56">
      <div class="shadow-2xl rounded-2xl p-8 lg:w-1/3 mx-5">
        <div class="flex justify-center items-center mb-5">
          <img
            :src="images.valider"
            alt="valider"
            width="48"
            height="48"
            original
          />
        </div>
        <div class="font-medium text-2xl text-gray-900 mb-2 text-center">
          {{ $t("candidatureT") }}
        </div>
        <div class="font-normal text-xl text-gray-500 mb-5 text-center">
          {{ $t("candidatureP") }}
        </div>
        <div class="flex justify-center" @click="openHome()">
          <button
            class="font-medium text-base text-white bg-identityColor hover:text-identityColor hover:bg-gray-200 hover:shadow-sm rounded-lg cursor-pointer px-6 py-4"
          >
            D’accord
          </button>
        </div>
      </div>
    </div>

    <!-----------------------------------
  Start Footer Component
  ------------------------------------>
    <Footer
      @openFormHeader="openFormHeader"
      @openPaymentNumber="openPaymentNumber"
    ></Footer>
    <!-----------------------------------
  End Footer Component
  ------------------------------------>
    <payment-number
      v-if="isPaymentNumber"
      @close="closePaymentNumber"
      @openTransactionModal="openTransactionModal"
    />
    <transaction-modal
      v-if="isTransactionModal"
      @close="closeTransactionModal"
      @closePaymentNumber="closePaymentNumber"
    />

    <!-- FORMULAIRE DE SOUSCRIPTION -->
    <formular-pass v-if="isForm" @closeForm="closeForm" @close="showSuccess" />
  </div>
</template>

<script>
/*Import Components */
import Header from "../../components/header/index.vue";
import Footer from "../../components/footer/index.vue";
import valider from "../../assets/images/img_valider.webp";
import FormularPass from "../../components/formular-pass";
import TransactionModal from "../../components/container/home/transaction-modal.vue";
import paymentNumber from "../../components/container/home/payment-number.vue";

export default {
  name: "index",
  metaInfo: {
    title: "bitcoinflash",
    meta: [
      {
        name: "bitcoinflash",
        content:
          "Acheter du credit, du forfait internet avec des satoshi en utilisant le lightning network",
      },
    ],
  },
  components: { Header, Footer, FormularPass, TransactionModal, paymentNumber },
  data() {
    return {
      icons: {},
      images: { valider },
      isForm: false,
      isTransactionModal: false,
      isPaymentNumber: false,
    };
  },
  methods: {
    openHome() {
      this.$router.push({ path: "/" });
    },
    openFormHeader(value) {
      this.isForm = value;
    },
    closeForm(value) {
      this.isForm = value;
      this.isSuccess = false;
    },
    openForm() {
      this.isForm = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/style/sass/variables.scss";

.img-fill {
  fill: transparent;
}
</style>
